import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import reportsReducer from './reports/reportsReducer';

//global
import applicationsReducer from 'redux/applications/applicationsReducer';
import filtersReducer from 'redux/filters/filtersReducer';
import loadingReducer from 'redux/loading/loadingReducer';
import roleReducer from 'redux/role/roleReducer';
import tokenReducer from 'redux/token/tokenReducer';
import userInfoReducer from 'redux/userInfo/userInfoReducer';

const rootReducer = combineReducers({
    applications: applicationsReducer,
    filter: filtersReducer,
    loading: loadingReducer,
    reports: reportsReducer,
    role: roleReducer,
    token: tokenReducer,
    userInfo: userInfoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default rootReducer;
